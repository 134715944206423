import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  ProductType,
  EditProductType,
  DetailProductType,
  ProductTransactionType,
  EditProductTransactionType,
  Warehouse,
  EditWarehouse,
  ProductStock,
  ProductHistory,
  Product,
  EditProduct,
  DetailProduct,
  DetailProductStock,
  Brand,
  DetailBrand,
  EditBrand,
  DetailWarehouse,
} from '../views/WMS';
import { AddMaterialStockLot } from '../views/IMS';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotFound, NotAuthorized } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';
import { CreateExport, CreateOrder } from '../views/SPM';
import ExportHistory from '../views/SPM/ExportHistory';
import ButtonHistory from '../components/Button/ButtonHistory';
import { BackButton } from '../components/Button';
import ImportHistory from '../views/SPM/ImportHistory';
import CutStock from '../views/SPM/CutStock';
import MoveHistory from '../views/SPM/MoveHistory';
// import HistoryTransaction from '../views/WMS/HistoryTransaction';

export function WMS() {
  const module = 'WMS';
  const prefix = '/wms';
  const name = 'คลังสินค้า';

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: WMS');
    dispatch(actions.meGet());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="min-h-screen  pt-16 px-4 flex w-full">
          <MainSidebar
            onMobileClose={handleOnMobileNavClose}
            openMobile={isMobileNavOpen}
            me={me}
          />

          <div className="py-4 w-full">
            <Switch>
              <Redirect
                exact
                from={`${prefix}`}
                to={`${prefix}/product-stock`}
              />
              <Route exact path={`${prefix}/product-stock`}>
                <ProductStock title="จัดการสต๊อกสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product-stock/warehouse/:id`}>
                <DetailProductStock title="จัดการสต๊อกสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product-stock/create/:id`}>
                <CreateOrder title="รับเข้าสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/export-stock/create/:id`}>
                <CreateExport title="ส่งออกสินค้า(ลูกค้า)" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/material-stock/create/:id`}>
                <AddMaterialStockLot
                  title="ส่งออกสินค้า(บีโบ้)"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/history-import/:id`}>
                <ImportHistory title="ประวัติการรับเข้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/history-export/:id`}>
                <ExportHistory title="ประวัติการส่งออก" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/history-move/:id`}>
                <MoveHistory title="ประวัติการย้ายคลังสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/history-cut-stock/:id`}>
                <CutStock title="ประวัติการตัดสต๊อกสินค้า" subtitle={name} />
              </Route>

              <Route exact path={`${prefix}/product-types`}>
                <ProductType title="จัดการประเภทสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product-types/edit/:id`}>
                <EditProductType title="แก้ไขประเภทสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product-type/:id`}>
                <DetailProductType
                  title="รายละเอียดประเภทสินค้า"
                  subtitle={name}
                />
              </Route>
              {/* <Route exact path={`${prefix}/product-historyproduct`}>
                <HistoryTransaction title="" subtitle={name} />
              </Route> */}
              <Route exact path={`${prefix}/brand`}>
                <Brand title="จัดการแบรนด์" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/brand/edit/:id`}>
                <EditBrand title="แก้ไขแบรนด์" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/brand/:id`}>
                <DetailBrand title="รายละเอียดแบรนด์" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/products`}>
                <Product title="จัดการข้อมูลสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product/edit/:id`}>
                <EditProduct title="แก้ไขข้อมูลสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product/:id`}>
                <DetailProduct title="รายละเอียดข้อมูลสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/transaction-types`}>
                <ProductTransactionType
                  title="จัดการประเภทการดำเนินการ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/transaction-types/edit/:id`}>
                <EditProductTransactionType
                  title="แก้ไขประเภทการดำเนินการ"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/warehouses`}>
                <Warehouse title="จัดการคลังสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/warehouse/:id`}>
                <DetailWarehouse title="รายละเอียดคลังสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/warehouse/edit/:id`}>
                <EditWarehouse title="แก้ไขคลังสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/history/`}>
                <ProductHistory
                  title="ประวัติการจัดการสต๊อกสินค้า"
                  subtitle={name}
                />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default WMS;
