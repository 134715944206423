/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { TextField, Button, Tooltip, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useForm, useFieldArray } from 'react-hook-form';
import CreateProduct from '../../Modal/CreateProduct';

import { AddSerialNumberDialog, AddOptionDialog } from '../../Dialogs';

import { SPM } from '../../../utils/Proptypes';

const resetValues = {
  product: '',
  inventory: '',
  selectSerial: [],
};

export const AddProductForm = ({
  errors,
  Controller,
  id,
  product,
  filterProduct,
  setFilterProduct,
  productList,
  setProductList,
  setReload,
  reload,
  selectSerial,
  setSelectSerial,
  setSelectOption,
  selectOption,
}) => {
  const [isModalCreateProductOpen, setIsModalCreateProductOpen] = useState(
    false,
  );
  const handleOpen = () => setIsModalCreateProductOpen(true);
  const handleClose = () => {
    setIsModalCreateProductOpen(false);
  };
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const { getValues, control, reset, setValue, watch } = useForm({
    mode: 'onBlur',
  });

  const { fields } = useFieldArray({
    control,
    name: 'optionList',
  });

  const NewProduct = _.map(product, (_product, index) => ({
    index: index + 1,
    ..._product,
  }));
  console.log('NewProduct', NewProduct);
  console.log('id in import', id);
  const ProductWarehouse = _.filter(NewProduct, (eachProductWarehouse) => {
    if (eachProductWarehouse?.warehouse === id) {
      return eachProductWarehouse;
    }
  });
  console.log('ProductWarehouse', ProductWarehouse);

  const checkProduct = (data) => {
    // eslint-disable-next-line no-underscore-dangle
    const findproduct = _.find(product, { id: data._id });
    setFilterProduct(findproduct);
  };
  useEffect(() => () => {}, [reload]);

  const handleCloseDialog = () => {
    try {
      setIsProductDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddProduct = () => {
    if (filterProduct?.serial_type === false && !filterProduct.option_type) {
      if (getValues('inventory') === '' || getValues('cost_price') === '') {
        alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
      } else {
        const data = {
          product: filterProduct,
          inventory: getValues('inventory'),
          cost_price: getValues('cost_price'),
        };
        console.log(data);
        productList.push(data);
        setProductList(productList);
        reset(resetValues);
        setReload(!reload);
      }
    } else if (filterProduct?.serial_type === true) {
      // eslint-disable-next-line no-lonely-if
      if (getValues('cost_price1') === '' || selectSerial.length === 0) {
        alert('กรุณาใส่ข้อมูลให้ครบถ้วน1');
      } else {
        const data = {
          product: filterProduct,
          inventory: selectSerial.length,
          serials: selectSerial,
          cost_price: getValues('cost_price1'),
        };
        console.log(data);
        productList.push(data);
        setProductList(productList);
        setSelectSerial([]);
        reset(resetValues);
        setReload(!reload);
      }
    } else if (filterProduct?.option_type === true) {
      console.log('สำหรับสินค้าที่มีออฟชั่น');
      if (
        _.sum(_.map(selectOption, (eachOption) => eachOption.quantity)) === 0
      ) {
        alert('กรุณาใส่จำนวนสินค้า');
      } else {
        console.log('selectOption on submit', selectOption);
        const data = {
          product: selectOption[0].product,
          price: selectOption[0].product.price,
          cost_price: filterProduct.cost_price,
          inventory: _.sum(
            _.map(selectOption, (eachOption) => eachOption.quantity),
          ),
          options: _.map(selectOption, (eachOption) => {
            const payload = {
              // eslint-disable-next-line no-underscore-dangle
              option: eachOption.options._id,
              quantity: eachOption.quantity,
            };
            return payload;
          }),
        };
        console.log('data in add', data);
        productList.push(data);
        setProductList(productList);
        setSelectOption([]);
      }
    }
  };

  return (
    <div>
      <CreateProduct
        isOpen={isModalCreateProductOpen}
        handleOnClose={handleClose}
      />
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2 flex ">
          <div className="flex-grow">
            <Controller
              name={'product'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  size={'small'}
                  disableClearable
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                    checkProduct(newValue);
                    setReload(!reload);
                    setValue('cost_price', newValue?.cost_price);
                  }}
                  options={ProductWarehouse}
                  getOptionLabel={(option) =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    `รหัส ${option?.type_code} :  ${option?.name}`
                  }
                  required
                  renderInput={(params) => (
                    <TextField {...params} label="สินค้า" required />
                  )}
                />
              )}
            />
          </div>
          <div className="my-auto pl-1  flex-none">
            <Tooltip
              title="เพิ่มสินค้ากรณีที่ยังไม่มีข้อมูลในระบบ"
              placement="bottom"
            >
              <Button variant="outlined" onClick={handleOpen}>
                เพิ่มสินค้า
              </Button>
            </Tooltip>
          </div>
        </div>
        {filterProduct?.serial_type === true && (
          <>
            <div className="w-full md:w-1/2 px-1 py-2">
              <Controller
                name={'cost_price1'}
                control={control}
                defaultValue={filterProduct ? filterProduct?.cost_price : ''}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ต้นทุน"
                    fullWidth
                    size={'small'}
                  />
                )}
              />
            </div>
            <div className="w-full md:w-1/2 px-1 py-2">
              <div className="w-full h-full">
                <Button
                  variant="outlined"
                  className="w-full h-full"
                  onClick={() => setIsProductDialogOpen(true)}
                >
                  <p className="bg-gray-200 p-0.5 px-2 font-bold rounded-full text-gray-800">
                    {selectSerial?.length}
                  </p>
                  <p className="pl-2">เพิ่ม SN</p>
                </Button>
              </div>
            </div>
            <AddSerialNumberDialog
              open={isProductDialogOpen}
              handleClose={handleCloseDialog}
              selectSerial={selectSerial}
              setSelectSerial={setSelectSerial}
            />
            <div className="w-full h-full px-1 py-2">
              <Button
                variant="contained"
                className="w-full h-full"
                onClick={() => handleAddProduct()}
              >
                เพิ่มลงรายการ
              </Button>
            </div>
          </>
        )}
        {filterProduct?.serial_type === false &&
          !filterProduct?.option_type === true && (
            <>
              <div className="w-full md:w-1/2 px-1 py-2">
                <Controller
                  name={'cost_price'}
                  control={control}
                  defaultValue={watch('product')?.cost_price}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="ต้นทุน"
                      fullWidth
                      size={'small'}
                    />
                  )}
                />
              </div>
              <div className="w-full md:w-1/2 px-1 py-2">
                <Controller
                  name={'inventory'}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="จำนวน"
                      fullWidth
                      type="number"
                      size={'small'}
                      // eslint-disable-next-line react/prop-types
                      helperText={errors.inventory && 'กรุณาใส่ข้อมูล'}
                    />
                  )}
                />
              </div>
              <div className="w-full px-1 py-2">
                <Button
                  variant="outlined"
                  className="w-full"
                  onClick={() => handleAddProduct()}
                >
                  เพิ่ม
                </Button>
              </div>
            </>
          )}
        {filterProduct?.option_type === true && (
          <>
            <div className="w-full px-1">
              <div className="w-full h-full">
                <Button
                  variant="outlined"
                  className="w-full h-full"
                  onClick={() => setIsProductDialogOpen(true)}
                >
                  <p className="bg-gray-200 p-0.5 px-2 font-bold rounded-full text-gray-800">
                    {_.sum(
                      _.map(selectOption, (eachOption) => eachOption.quantity),
                    )}
                  </p>
                  <p className="pl-2">เพิ่มสินค้าตามออฟชั่น</p>
                </Button>
              </div>
            </div>
            <AddOptionDialog
              open={isProductDialogOpen}
              handleClose={handleCloseDialog}
              filterProduct={filterProduct}
              setValue={setValue}
              fields={fields}
              setSelectOption={setSelectOption}
              selectOption={selectOption}
            />
            <div className="w-full h-full px-1 py-2">
              <Button
                variant="contained"
                className="w-full h-full"
                onClick={() => handleAddProduct()}
              >
                เพิ่ม
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

AddProductForm.propTypes = {
  errors: PropTypes.shape({
    product_transaction_type: PropTypes.object,
    orderNumber: PropTypes.billNumber,
    customer: PropTypes.array,
    source: PropTypes.object,
    quantity: PropTypes.object,
    price: PropTypes.object,
    product_stock_lots: PropTypes.object,
    warehouse: PropTypes.object,
    product_type: PropTypes.array,
    productionDate: PropTypes.object,
    expirationDate: PropTypes.object,
    receiptDate: PropTypes.object,
    sellDate: PropTypes.object,
    remark: PropTypes.object,
    recipientName: PropTypes.object,
    sellName: PropTypes.object,
    trackingNumber: PropTypes.object,
    sellPrice: PropTypes.object,
    shippingCost: PropTypes.object,
    discount: PropTypes.object,
    payment: PropTypes.payment,
  }),
  product: PropTypes.array,
  productType: PropTypes.array,
  productTransactionType: PropTypes.array,
  order: PropTypes.oneOf([SPM.Order, SPM.OrderArray]),
  Controller: PropTypes.func,
  control: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  selectProduct: PropTypes.array,
  setSelectProduct: PropTypes.func,
  filterProduct: PropTypes.object,
  setFilterProduct: PropTypes.func,
  customers: PropTypes.array,
  departments: PropTypes.object,
  products: PropTypes.object,
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  employees: PropTypes.array,
  productTypes: PropTypes.object,
  productList: PropTypes.array,
  setProductList: PropTypes.func,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  selectSerial: PropTypes.array,
  setSelectSerial: PropTypes.func,
  selectOption: PropTypes.array,
  setSelectOption: PropTypes.func,
};

AddProductForm.defaultProps = {
  product: null,
  productType: null,
  productTransactionType: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  selectProduct: null,
  filterProduct: null,
};

export default AddProductForm;
