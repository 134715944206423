import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Card, Button } from '@mui/material';

import { Paperclip } from 'react-feather';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import SaveIcon from '@mui/icons-material/Save';

import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { EditProductForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import FileUpload from '../../components/fileUpload/FileUpload';
import VideoUpload from '../../components/fileUpload/VideoUpload';

export default function EditProduct({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const warehouse = useSelector((state) => state.warehouse);
  const brand = useSelector((state) => state.brand);
  const [selectOption, setSelectOption] = useState([]);
  const [serialType, setSerialType] = useState(product?.serial_type);
  const [privatePrice, setPrivatePrice] = useState(product?.private_price);
  const [uploadedImage, setUploadedImage] = useState(product?.images);
  const [productDescribe, setProductDescribe] = useState(product?.detail);
  const [delivery, setDelivery] = useState(product?.delivery);
  const [showFront, setShowFront] = useState(product?.show_front);
  const [popularProduct, setPopularProduct] = useState(
    product?.popular_product,
  );
  // const [taxInclude, setTaxInclude] = useState(product?.tax_include);

  const [file, setFile] = useState(product?.files || []);
  const [video, setVideo] = useState(product?.videos || []);

  console.log('video', video);
  console.log('Ware house ', warehouse);

  const [modalFileOpen, setModalFileOpen] = useState(false);
  const [modalVideoOpen, setModalVideoOpen] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      name: product.name,
      warehouse: product.warehouse,
      unit: product.unit,
      packing_size: product.packing_size,
      packing_unit: product.packing_unit,
      net: product.net,
      unit_net: product.net,
      detail: product.detail,
      type_code: product.type_code,
      option: product.option,
      weight: product.weight,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'optionList',
  });

  console.log('file ', file);

  useEffect(() => {
    dispatch(actions.productGet(id));
    dispatch(actions.warehouseAll(''));
    dispatch(actions.productTypeAll(''));
    dispatch(actions.brandAll(''));
    return () => {};
  }, []);

  useEffect(() => {
    if (!product.isLoading) {
      setValue('optionList', product?.options);
    }
    setFile(product?.files || []);
    setVideo(product?.videos || []);
    setUploadedImage(product?.images);
    setProductDescribe(product?.detail);
    setDelivery(product?.delivery);
    setShowFront(product?.show_front);
    setPopularProduct(product?.popular_product);
    // setTaxInclude(product?.tax_include);
    return () => {};
  }, [product]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      const payload = {
        ...product,
        type_code: data.type_code,
        name: data.name,
        detail: productDescribe,
        price: data.price,
        cost_price: data.cost_price,
        unit: data.unit,
        // serial_type: data.serial_type,
        private_price: data.private_price,
        product_type: data.product_type,
        brand: data.brand,
        warehouse: data?.warehouse,
        show_front: data.show_front,
        popular_product: data.popular_product,
        // tax_include: data.tax_include,
        stock_infinite: data.stock_infinite,
        pre_order: data.pre_order,
        delivery: data.delivery,
        weight: data.weight,
        options: _.map(data.optionList, (option) => ({
          // eslint-disable-next-line no-underscore-dangle
          option_id: option._id,
          name: option.name,
          inventory: option.inventory,
          weight: parseInt(option.weight, 10),
          pack_size: parseInt(option.pack_size, 10),
          price: parseInt(option?.price, 10),
          cost: parseInt(option?.cost, 10),
        })),
        images: _.map(uploadedImage, (image) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: image._id,
          image: image.data_url || image.url,
        })),
        minimum_inventory_size: data.minimum_inventory_size,
        file: _.isEmpty(file) ? undefined : file,
        video: _.isEmpty(video) ? undefined : video,
      };
      // console.log('data after submit', data);
      console.log('payload after submit', payload);
      await dispatch(actions.productPut(id, payload));
      await dispatch(actions.productGet(id));
      setFile([]);
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  // console.log('product', product);
  if (product.isLoading || product.rows) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        <FileUpload
          isOpen={modalFileOpen}
          files={file}
          setFiles={setFile}
          handleClose={() => setModalFileOpen(false)}
        />
        <VideoUpload
          isOpen={modalVideoOpen}
          files={video}
          setFiles={setVideo}
          handleClose={() => setModalVideoOpen(false)}
        />
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">แก้ไขข้อมูลสินค้า</div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <EditProductForm
                errors={errors}
                product={product}
                warehouse={warehouse}
                control={control}
                Controller={Controller}
                productType={productType}
                brand={brand}
                option={product.option}
                selectOption={selectOption}
                setSelectOption={setSelectOption}
                serialType={serialType}
                setSerialType={setSerialType}
                privatePrice={privatePrice}
                setPrivatePrice={setPrivatePrice}
                uploadedImage={uploadedImage}
                setUploadedImage={setUploadedImage}
                productDescribe={productDescribe}
                setProductDescribe={setProductDescribe}
                delivery={delivery}
                options={fields}
                append={append}
                remove={remove}
                popularProduct={popularProduct}
                // taxInclude={taxInclude}
                showFront={showFront}
                file={file}
                video={video}
              />
              {_.size(file) > 0 && (
                <ul className="list-disc list-inside mx-2 my-3">
                  {_.map(file, (_file, index) => (
                    <div className="flex">
                      {_file?.file_name ? (
                        <a
                          href={_file?.url}
                          target="_blank"
                          rel="noreferrer"
                          className="my-auto "
                        >
                          <li
                            className="font-system my-auto hover:underline"
                            key={index}
                          >
                            {_file?.file_name}
                          </li>
                        </a>
                      ) : (
                        <li className="font-system my-auto" key={index}>
                          {_file?.path}
                        </li>
                      )}

                      <Button
                        onClick={() => {
                          const NewArray = [...file];
                          // eslint-disable-next-line react/prop-types
                          NewArray.splice(index, 1);
                          setFile(NewArray);
                        }}
                      >
                        ลบ
                      </Button>
                    </div>
                  ))}
                </ul>
              )}
              <div className="px-1 pt-4">
                <div className="flex justify-center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalFileOpen(true)}
                    startIcon={<Paperclip size={16} />}
                  >
                    อัพโหลดไฟล์
                  </Button>
                </div>
              </div>
              <>
                <div className="px-1 w-full pt-4">
                  <div className="p-2 bg-gray-600 text-white my-2 w-full">
                    วีดีโอ
                  </div>
                </div>
              </>
              {_.size(video) > 0 && (
                <ul className="list-disc list-inside mx-2 my-3">
                  {_.map(video, (_video, index) => (
                    <div className="flex">
                      {_video?.file_name ? (
                        <a
                          href={_video?.url}
                          target="_blank"
                          rel="noreferrer"
                          className="my-auto "
                        >
                          <li
                            className="font-system my-auto hover:underline"
                            key={index}
                          >
                            {_video?.file_name}
                          </li>
                        </a>
                      ) : (
                        <li className="font-system my-auto" key={index}>
                          {_video?.path}
                        </li>
                      )}

                      <Button
                        onClick={() => {
                          const NewArray = [...video];
                          // eslint-disable-next-line react/prop-types
                          NewArray.splice(index, 1);
                          setVideo(NewArray);
                        }}
                      >
                        ลบ
                      </Button>
                    </div>
                  ))}
                </ul>
              )}
              <div className="px-1 pt-4">
                <div className="flex justify-center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setModalVideoOpen(true)}
                    startIcon={<VideoFileIcon size={16} />}
                  >
                    อัพโหลดวีดีโอ
                  </Button>
                </div>
              </div>
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditProduct.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProduct.defaultProps = {
  title: '',
  subtitle: '',
};
