/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
  Table,
  TableContainer,
} from '@mui/material';
import _ from 'lodash';

export default function Materailmove({
  control,
  controlStock = 'productMove',
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: controlStock,
  });
  return (
    <div className="w-full flex flex-row flex-wrap">
      <div className="w-full l px-1 py-2 ">
        <div className="text-xl py-2">สินค้าในคลัง</div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell> ลำดับ</TableCell>
                <TableCell>สินค้า </TableCell>
                <TableCell>จำนวนคงเหลือ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(fields) ? (
                _.map(fields, (row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    {console.log('row', row)}
                    <div className="  text-left pl-6 mt-6 ">{index + 1}</div>
                    <TableCell component="th" scope="row">
                      <div className="text-xs">
                        <Controller
                          control={control}
                          name={`${controlStock}[${index}].name`}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              defaultChecked={field.value}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="text-xs">
                        <Controller
                          control={control}
                          name={`${controlStock}[${index}].inventory`}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              defaultChecked={field.value}
                            />
                          )}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              {/* <Button type="button" onClick={() => append({})}>
                {' '}
                เพิ่ม
              </Button> */}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <Controller
        name={'product'}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              สินค้า
            </InputLabel>
            <Select
              {...field}
              label="สินค้า"
              size={'small'}
              fullWidth
              onChange={(e) => {
                field.onChange(e.target.value);
                checkMaterial(e.target.value);
              }}
            >
              {_.size(warehouseproductIndex) ? (
                _.map(warehouseproductIndex, (stock) => (
                  <MenuItem key={stock.id} value={stock.id}>
                    {stock.name} ( เหลือสินค้า
                    {
                      _.find(stock.warehouses, (eachwarehouse) => {
                        return eachwarehouse?.warehouse === id;
                      })?.inventory
                    }{' '}
                    {stock?.unit} )
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <MenuItem disabled value="">
                    <em>ไม่มีข้อมูล</em>
                  </MenuItem>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        )}
      /> */}
      </div>
      {/* <div className="w-full lg:w-1/2 px-1 py-2">
      <Controller
        name={'quantity'}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="จำนวน"
            fullWidth
            size={'small'}
            helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
          />
        )}
      />
    </div> */}

      <div className="w-full lg:w-full px-1 py-2">
        <Controller
          name={'remark'}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              size={'small'}
              multiline={true}
              rows={3}
              //   helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
  // eslint-disable-next-line no-else-return
}
