/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
import React from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
  Table,
  TableContainer,
} from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import Materailmove from './Materailmove';

export function MaterialStockForm({
  errors,
  Controller,
  control,
  product,
  id,
  place,
  materialTransactionType,
  department,
  me,
  type,
  setType,
  selectMaterial,
  setSelectMaterial,
  filterMaterial,
  filterPlace,
  setFilterPlace,
}) {
  // console.log('me', me);
  console.log('type', type);
  console.log('selectMaterial', selectMaterial);
  console.log('filterMaterial', filterMaterial);
  console.log('filterPlace', filterPlace);
  console.log('Me ', me);

  const checkType = (data) => {
    const findType = _.find(materialTransactionType, { id: data });
    setType(findType);
  };
  const NewProduct = _.map(product, (_product, index) => ({
    index: index + 1,
    ..._product,
  }));
  console.log('NewProduct', NewProduct);
  const warehouseproductIndex = [];
  _.map(NewProduct, (each) => {
    _.map(each.warehouses, (wh) => {
      if (wh.warehouse === id) {
        warehouseproductIndex.push(each);
      }
    });
  });

  console.log('warehouseproductIndex', warehouseproductIndex);
  const checkPlace = (data) => {
    const findPlace = _.find(place, { id: data });
    setFilterPlace(findPlace);
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <div className="text-bold text-lg text-center">เบิกไปยังคลัง</div>
        <Controller
          name={'after_warehouse'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                คลังเบิก
              </InputLabel>
              <Select
                {...field}
                label="คลัง"
                size={'large'}
                fullWidth
                onChange={(e) => {
                  field.onChange(e.target.value);
                  checkPlace(e.target.value);
                }}
              >
                {_.size(place) ? (
                  _.map(place, (_place) => (
                    <MenuItem key={_place.id} value={_place.id}>
                      {_place.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
        <div className="grid grid-cols-2">
          <div className="w-full px-1 pt-2">
            <label>วันที่เบิก</label>
            <Controller
              name={'date'}
              control={control}
              defaultValue={dayjs(new Date()).locale('th').format('YYYY-MM-DD')}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  format={'DD/MM/YYYY'}
                  fullWidth
                  size={'small'}
                  helperText={errors.expirationDate && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          {/* <div className="w-full  px-1 pt-8 ">
            <Controller
              name={'employee'}
              control={control}
              rules={{ required: true }}
              defaultValue={`${me?.firstname || ''} `}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้เบิก"
                  fullWidth
                  size={'small'}
                  helperText={errors.recipientName && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div> */}
        </div>
      </div>
      <Materailmove
        product={product}
        place={place}
        materialTransactionType={materialTransactionType}
        Controller={Controller}
        control={control}
        errors={errors}
        id={id}
        controlStock={'productMove'}
      />
    </div>
  );
}

MaterialStockForm.propTypes = {
  errors: PropTypes.shape({
    material_transaction_type: PropTypes.object,
    billNumber: PropTypes.billNumber,
    supplier: PropTypes.object,
    source: PropTypes.object,
    quantity: PropTypes.object,
    price: PropTypes.object,
    material_stock_Lots: PropTypes.object,
    place: PropTypes.object,
    material_type: PropTypes.object,
    productionDate: PropTypes.object,
    expirationDate: PropTypes.object,
    purchaseDate: PropTypes.object,
    receiptDate: PropTypes.object,
    remark: PropTypes.object,
    recipientName: PropTypes.object,
    orderDepartment: PropTypes.object,
    requestDepartment: PropTypes.object,
    requestName: PropTypes.object,
  }),
  material: PropTypes.object,
  materialType: PropTypes.object,
  materialTransactionType: PropTypes.object,
  materialStockLot: PropTypes.object,
  place: PropTypes.object,
  customer: PropTypes.object,
  department: PropTypes.object,
  me: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  selectMaterial: PropTypes.object,
  setSelectMaterial: PropTypes.func,
  filterMaterial: PropTypes.object,
  setFilterMaterial: PropTypes.func,
  filterPlace: PropTypes.object,
  setFilterPlace: PropTypes.func,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

MaterialStockForm.defaultProps = {
  material: null,
  materialType: null,
  materialTransactionType: null,
  materialStockLot: null,
  place: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  selectMaterial: null,
  filterMaterial: null,
  filterPlace: null,
};

export default MaterialStockForm;
