/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import _ from 'lodash';
import { TextField, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { HRMS, SPM } from '../../../utils/Proptypes';

export const MoveForm = ({
  errors,
  id,
  // order,
  customers,
  Controller,
  control,
  employees,
  // setSelectedProducts,
  me,
}) => {
  const [reload, setReload] = useState(false);
  const NewCustomer = _.map(customers, (customer, index) => ({
    index: index + 1,
    ...customer,
  }));

  const CustomerWarehouse = _.filter(
    NewCustomer,
    // eslint-disable-next-line consistent-return
    (eachCustomerWarehouse) => {
      if (
        eachCustomerWarehouse?.type?.warehouse === id &&
        eachCustomerWarehouse?.name === 'บีโบ้ช็อป'
      ) {
        return eachCustomerWarehouse;
      }
    },
  );
  console.log('คลังบีโบ้', CustomerWarehouse);

  return (
    <div>
      <div className="flex flex-row flex-wrap">
        <div className="flex-grow px-1">
          <Controller
            name={'customer'}
            control={control}
            rules={{ required: true }}
            defaultValue={CustomerWarehouse?.[0]}
            render={({ field }) => (
              <Autocomplete
                size={'small'}
                disableClearable
                {...field}
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                  setReload(!reload);
                }}
                options={CustomerWarehouse}
                getOptionLabel={(option) =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  ` ${option?.name}`
                }
                required
                renderInput={(params) => (
                  <TextField {...params} label="คู่ค้า" required />
                )}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'volume_no'}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField {...field} label="เล่มที่" fullWidth size={'small'} />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'bill_no'}
            control={control}
            defaultValue={''}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขที่บิล"
                fullWidth
                size={'small'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <div className="py-1">วันส่งออกสินค้า</div>
          <Controller
            name={'date'}
            control={control}
            defaultValue={dayjs(new Date()).locale('th').format('YYYY-MM-DD')}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                type="date"
                fullWidth
                size={'small'}
                helperText={errors.expirationDate && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <div className="w-full px-1 py-2">
            <Controller
              name={'employee'}
              control={control}
              rules={{ required: false }}
              defaultValue={
                `${me?.userData?.firstname} ${me?.userData?.lastname}` ||
                employees[0]
              }
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ดำเนินการโดย"
                  disabled
                  fullWidth
                  size={'small'}
                />
              )}
            />
          </div>
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'remark'}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="หมายเหตุ"
                fullWidth
                size={'small'}
                rows={3}
                multiline={true}
                helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

MoveForm.propTypes = {
  errors: PropTypes.shape({
    product_transaction_type: PropTypes.object,
    orderNumber: PropTypes.billNumber,
    customer: PropTypes.array,
    source: PropTypes.object,
    quantity: PropTypes.object,
    price: PropTypes.object,
    product_stock_lots: PropTypes.object,
    warehouse: PropTypes.object,
    product_type: PropTypes.array,
    productionDate: PropTypes.object,
    expirationDate: PropTypes.object,
    receiptDate: PropTypes.object,
    sellDate: PropTypes.object,
    remark: PropTypes.object,
    recipientName: PropTypes.object,
    sellName: PropTypes.object,
    trackingNumber: PropTypes.object,
    sellPrice: PropTypes.object,
    shippingCost: PropTypes.object,
    discount: PropTypes.object,
    payment: PropTypes.payment,
  }),
  product: PropTypes.array,
  productType: PropTypes.array,
  productTransactionType: PropTypes.array,
  order: PropTypes.oneOf([SPM.Order, SPM.OrderArray]),
  Controller: PropTypes.func,
  control: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  selectProduct: PropTypes.array,
  setSelectProduct: PropTypes.func,
  filterProduct: PropTypes.object,
  setFilterProduct: PropTypes.func,
  customers: PropTypes.array,
  departments: HRMS.departmentArray,
  watch: PropTypes.func.isRequired,
  products: PropTypes.object,
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  employees: PropTypes.array,
  productTypes: PropTypes.object,
  online: PropTypes.bool,
  setOnline: PropTypes.func,
  me: PropTypes.object,
};

MoveForm.defaultProps = {
  product: null,
  productType: null,
  productTransactionType: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  selectProduct: null,
  filterProduct: null,
};

export default MoveForm;
