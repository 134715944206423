import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// import { io } from 'socket.io-client';
// import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';

import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import MapIcon from '@mui/icons-material/Map';
// ref : https://react-google-maps-api-docs.netlify.app/
// ref : https://www.npmjs.com/package/@react-google-maps/api

import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { CustomerForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { CSVUpload } from '../../components/CSVUpload';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValues = {
  type: '',
  organization_type: '',
  name: '',
  address: '',
  taxes: '',
  phone_number: '',
  fax: '',
  email: '',
  payment: '',
  source: '',
};

const containerStyle = {
  width: '100%',
  height: '400px',
};
const placesLibrary = ['places'];

function Customers({ title, subtitle }) {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const customerType = useSelector((state) => state.customerType);
  const driverLine = useSelector((state) => state.driverLine);
  const setting = useSelector((state) => state.setting);
  const history = useHistory();
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [selectCustomer, setSelectCustomer] = useState('');
  const [selectDriverLine, setSelectDriverLine] = useState('');

  const [addUser, setAddUser] = useState(false);
  const [addSupplier, setAddSupplier] = useState(false);
  const [dropShip, setDropShip] = useState(false);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [customerMapMarkersOpen, setCustomerMapMarkersOpen] = useState(false);
  const [positionCurrentCenter, setPositionCurrentCenter] = useState({
    lat: parseFloat(setting?.owner?.position?.lat) || 13.7563309,
    lng: parseFloat(setting?.owner?.position?.lng) || 100.5017651,
  });
  const [csvData, setCsvData] = useState([]);

  console.log('setting in customer', setting);

  const [markersAllCustomer, setMarkersAllCustomer] = useState([]);

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  const handleCustomerMapMarkersOpen = () => setCustomerMapMarkersOpen(true);
  const handleCustomerMapMarkersClose = () => {
    setCustomerMapMarkersOpen(false);
  };

  console.log('csvData', csvData);

  const handleOpen = () => {
    // socket.emit('sent-message', dayjs().format('hh mm ss'));
    setOpen(true);
  };
  const handleClose = () => {
    setAddUser(false);
    setOpen(false);
    reset(defaultValues);
  };

  useEffect(() => {
    dispatch(actions.userReset());
    dispatch(
      actions.customerAll({
        name,
        page,
        size,
        selectCustomer,
        selectDriverLine,
      }),
    );
    dispatch(actions.customerTypeAll(''));
    dispatch(actions.driverLineAll(''));
    dispatch(actions.settingAll());
    return () => {};
  }, [name, page, size, selectCustomer, selectDriverLine]);

  console.log('setting', setting?.url);

  useEffect(() => {
    setTotal(customer?.total);
    return () => {};
  }, [customer]);

  useEffect(() => {
    const filterCustomerAddress = _.filter(customer?.rows, (e) => e?.position);
    console.log('filterCustomerAddress', filterCustomerAddress);
    const customerAddressArray = _.map(filterCustomerAddress, (e, i) => {
      const payload = {
        id: i + 1,
        name: e?.name,
        position: e?.position,
      };
      return payload;
    });
    const settingPosition = {
      id: 0,
      name: setting?.name,
      position: setting?.owner?.position,
    };

    customerAddressArray.push(settingPosition);

    console.log('customerAddressArray', customerAddressArray);
    setMarkersAllCustomer(customerAddressArray);
    setPositionCurrentCenter({
      lat: parseFloat(setting?.owner?.position?.lat) || 13.7563309,
      lng: parseFloat(setting?.owner?.position?.lng) || 100.5017651,
    });
    return () => {};
  }, [customer]);

  console.log('markersAllCustomer', markersAllCustomer);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      console.log('data', data);
      const dataEnd = {
        ...data,
        supplier: addSupplier,
        drop_ship: dropShip,
        user_type: 'customer',
        driver_line: data?.driver_line === '' ? undefined : data?.driver_line,
      };
      console.log('data', data);
      console.log('dataEnd', dataEnd);
      if (addUser === true) {
        if (data.password === data.confirmPassword) {
          try {
            await dispatch(actions.userRegisterCustomer(dataEnd));
            reset(defaultValues);
            alert('สำเร็จ');
            handleClose();
            setAddUser(false);
            await dispatch(actions.employeeAll({ name, page, size }));
          } catch (error) {
            alert('ขออภัย มีชื่อผู้ใช้นี้ในระบบแล้ว');
          }
        } else {
          alert('กรุณาใส่รหัสผ่านให้ตรงกัน');
        }
      } else {
        event.preventDefault();
        await dispatch(actions.customerCreate(dataEnd));
        setAddUser(false);
        reset(defaultValues);
        alert('สำเร็จ');
        handleClose();
      }

      await dispatch(actions.customerAll({ name, page, size }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteCustomer = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.customerDelete(id));
        await dispatch(actions.customerAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToDetailCustomer = async (id) => {
    history.push(`/crm/customer/${id}`);
  };

  const handlePushToEditCustomer = async (id) => {
    history.push(`/crm/customer/edit/${id}`);
  };

  const handlePushToEditUser = async (id) => {
    history.push(`/crm/user/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeType = (event) => {
    console.log('type customer', event.target.value);
    setSelectCustomer(event.target.value);
  };

  const handleChangeDriverLine = (event) => {
    console.log('Line', event.target.value);
    setSelectDriverLine(event.target.value);
  };

  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      console.log('csvData', csvData);
      const NewCSVData = _.map(csvData, (each) => {
        const payload = {
          ...each,
          addUser: String(each?.addUser).toLowerCase() === 'true',
        };
        return payload;
      });

      console.log('NewCSVData', NewCSVData);

      try {
        await dispatch(actions.customerCreate({ arr: NewCSVData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.customerAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มลูกค้าไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการลูกค้าได้');
    }
  };
  // console.log('selectCustomer', selectCustomer);

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button
        startIcon={<AddBoxIcon />}
        variant="contained"
        onClick={handleOpen}
      >
        เพิ่ม
      </Button>
      <Button
        startIcon={<MapIcon />}
        variant="contained"
        onClick={handleCustomerMapMarkersOpen}
      >
        แผนที่รวม
      </Button>
      <Button
        variant="contained"
        onClick={handleCSVOpen}
        startIcon={<FileUploadIcon />}
      >
        อัพโหลด
      </Button>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2 pl-1">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              ประเภทลูกค้า
            </InputLabel>
            <Select
              label="ประเภทลูกค้า"
              size={'small'}
              value={selectCustomer}
              onChange={handleChangeType}
              fullWidth
            >
              <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
              {_.size(customerType.rows) ? (
                _.map(customerType.rows, (row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <MenuItem disabled value="">
                    <em>ไม่มีข้อมูล</em>
                  </MenuItem>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
        <div className="w-full md:w-1/2 pl-1">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              เส้นทาง
            </InputLabel>
            <Select
              label="เส้นทาง"
              size={'small'}
              value={selectDriverLine}
              onChange={handleChangeDriverLine}
              fullWidth
            >
              <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
              {_.size(driverLine.rows) ? (
                _.map(driverLine.rows, (row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <MenuItem disabled value="">
                    <em>ไม่มีข้อมูล</em>
                  </MenuItem>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      </div>
    </Card>
  );

  console.log('customerMapMarkersOpen', customerMapMarkersOpen);

  const renderModalCustomerMapMarkers = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={customerMapMarkersOpen}
      onClose={handleCustomerMapMarkersClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={customerMapMarkersOpen}>
        <Card sx={style} className="max-w-4xl">
          <div className="w-full">
            {' '}
            <div id="google-map" className="p-1">
              <LoadScript
                googleMapsApiKey="AIzaSyAp-85dLfh4ua90wz5TmIOmqoaA64h4b1U"
                libraries={placesLibrary}
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={positionCurrentCenter}
                  zoom={5}
                >
                  {markersAllCustomer.map(
                    ({ id, name: customerName, position }) => (
                      <Marker
                        key={id}
                        position={{
                          lat: parseFloat(position?.lat),
                          lng: parseFloat(position?.lng),
                        }}
                        onClick={() => handleActiveMarker(id)}
                      >
                        {activeMarker === id ? (
                          <InfoWindow
                            onCloseClick={() => setActiveMarker(null)}
                          >
                            <div className="w-full">
                              <div>{customerName}</div>
                              <a
                                href={`https://www.google.com/maps/search/?api=1&query=${position?.lat},${position?.lng}`}
                                target="_blank"
                                rel="noreferrer"
                                className="w-full text-center bg-green-400 text-white text-xl rounded-md cursor-pointer mx-1 hover:shadow-md"
                              >
                                นำทาง
                              </a>
                            </div>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    ),
                  )}
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="w-full overflow-y-auto max-h-screen">
            <div className="py-2">เพิ่มข้อมูล</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomerForm
                  Controller={Controller}
                  control={control}
                  errors={errors}
                  customerType={customerType}
                  addSupplier={addSupplier}
                  setAddSupplier={setAddSupplier}
                  setDropShip={setDropShip}
                  dropShip={dropShip}
                  driverLine={driverLine}
                  setAddUser={setAddUser}
                  addUser={addUser}
                />
                <div className="flex flex-row justify-end gap-1 py-4">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderCSVUploadModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={csvUploadOpen}
      onClose={handleCSVClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={csvUploadOpen}>
        <Card sx={style} className="max-w-6xl">
          <div className="max-h-screen overflow-y-auto my-10">
            <div className="pt-2 text-xl">อัพโหลดข้อมูล</div>
            <div className="py-2 font-system">
              อัพโหลดไฟล์ .csv โดยมีโครงสร้างตามไฟล์เทมเพลต แถวที่ 1-2
              และแถวสุดท้าย จะไม่ถูกนำมาคิดในระบบ <br />
              <Button variant="contained">
                <Link to="/filetemplate/Customer.csv" target="_blank">
                  ดาวน์โหลดเทมเพลต
                </Link>
              </Button>
            </div>
            <div className="py-2">
              <div className="flex justify-center">
                <div>
                  <CSVUpload setCsvData={setCsvData} />
                  {!_.isEmpty(csvData) && (
                    <div>
                      <p className="font-system my-1">
                        พบข้อมูล {_.size(csvData)} รายการ
                      </p>
                      <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={handleAddFromFile}
                      >
                        บันทึก
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="my-2 flex">
              <div className="bg-blue-50 p-4 w-1/2">
                <div className="pb-2">
                  * โปรดแทนที่ข้อมูลใน <strong>ประเภทลูกค้า</strong>{' '}
                  ด้วยรหัสดังต่อไปนี้
                </div>
                <div className="rounded-sm overflow-y-auto">
                  <Table
                    sx={{
                      '&:last-child td, &:last-child th': { border: 1 },
                    }}
                  >
                    <TableHead>
                      <TableCell>ลำดับ</TableCell>
                      <TableCell>ชื่อประเภทของลูกค้า</TableCell>
                      <TableCell>รหัส</TableCell>
                    </TableHead>
                    <TableBody>
                      {_.map(customerType?.rows, (_customerType, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1} </TableCell>
                          <TableCell>{_customerType?.name} </TableCell>
                          <TableCell>
                            <a
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  _customerType?.id,
                                );
                                alert('คัดลอกเรียบร้อย');
                              }}
                            >
                              {_customerType?.id}
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
              <div className="bg-blue-50 p-4 w-1/2">
                <div className="pb-2">
                  * โปรดแทนที่ข้อมูลใน <strong>ประเภทการชำระเงิน</strong>{' '}
                  ด้วยข้อความดังต่อไปนี้
                </div>
                <div className="rounded-sm overflow-y-auto">
                  <Table
                    sx={{
                      '&:last-child td, &:last-child th': { border: 1 },
                    }}
                  >
                    <TableHead>
                      <TableCell>ลำดับ</TableCell>
                      <TableCell>ชื่อประเภทการชำระเงิน</TableCell>
                      <TableCell>ข้อความ</TableCell>
                    </TableHead>
                    <TableBody>
                      <TableRow key="1">
                        <TableCell>1 </TableCell>
                        <TableCell>เงินสด </TableCell>
                        <TableCell>เงินสด </TableCell>
                      </TableRow>
                      <TableRow key="2">
                        <TableCell>1 </TableCell>
                        <TableCell>โอนเข้าบัญชี </TableCell>
                        <TableCell>โอนเข้าบัญชี </TableCell>
                      </TableRow>
                      {/* <TableRow key="3">
                        <TableCell>1 </TableCell>
                        <TableCell>เครดิต 30 วัน </TableCell>
                        <TableCell>30day_credit </TableCell>
                      </TableRow>
                      <TableRow key="4">
                        <TableCell>1 </TableCell>
                        <TableCell>เครดิต 60 วัน</TableCell>
                        <TableCell>60day_credit </TableCell>
                      </TableRow> */}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(customer.rows) ? (
                customer.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div>{`${row?.name}`}</div>
                      {row?.code_name && <div>({`${row?.code_name}`})</div>}
                      {row?.drop_ship && (
                        <div className="text-yellow-600">(ตัวแทน)</div>
                      )}
                    </TableCell>
                    <TableCell>{`${row?.type?.name || '-'}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          startIcon={<EditIcon />}
                          size={'small'}
                          // eslint-disable-next-line no-underscore-dangle
                          onClick={() => handlePushToEditUser(row?.uid)}
                          disabled={!row?.uid}
                        >
                          จัดการผู้ใช้
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<DescriptionIcon />}
                          color={'info'}
                          size={'small'}
                          onClick={() => handlePushToDetailCustomer(row.id)}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<EditIcon />}
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditCustomer(row.id)}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteCustomer(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (customer.isLoading) {
    return <Loading />;
  }
  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        {renderModal()}
        {renderModalCustomerMapMarkers()}
        {renderCSVUploadModal()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

Customers.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Customers.defaultProps = {
  title: '',
  subtitle: '',
};

export default Customers;
