/* eslint-disable react/prop-types */
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function CustomerTypeForm({
  errors,
  customerType,
  Controller,
  warehouse,
  control,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'type_code'}
          control={control}
          defaultValue={customerType ? customerType.type_code : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัส"
              fullWidth
              size={'small'}
              helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={customerType ? customerType.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 pl-1">
        <Controller
          name={'warehouse'}
          control={control}
          // eslint-disable-next-line no-underscore-dangle
          defaultValue={customerType ? customerType?.warehouse : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="brand" size={'small'}>
                คลัง
              </InputLabel>
              <Select {...field} label="แบรนด์" size={'small'} fullWidth>
                <MenuItem>
                  <MenuItem value={null}>
                    <em>ไม่เลือก</em>
                  </MenuItem>
                </MenuItem>
                {_.size(warehouse?.rows) ? (
                  _.map(warehouse.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'description'}
          control={control}
          defaultValue={customerType ? customerType.description : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              multiline
              rows={4}
              size={'small'}
              helperText={errors.descrtiption && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

CustomerTypeForm.propTypes = {
  customerType: PropTypes.object,
  errors: PropTypes.shape({
    name: PropTypes.object,
    type_code: PropTypes.object,
    descrtiption: PropTypes.object,
  }),
  Controller: PropTypes.func,
  control: PropTypes.object,
};

CustomerTypeForm.defaultProps = {
  customerType: null,
};

export default CustomerTypeForm;
