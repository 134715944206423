/* eslint-disable import/extensions */
import React, { useEffect } from 'react';

import _ from 'lodash';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  // Autocomplete,
} from '@mui/material';

import { useParams } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';

import { Trash2 as Delete } from 'react-feather';

import PropTypes from 'prop-types';
import * as actions from '../../../redux/actions';
// import EditOptionForm from './EditOptionForm';
import ImageUpload from '../../ImageUpload/ImageUpload';
// eslint-disable-next-line import/no-unresolved
import RichtextEditor from '../../Richtext/RichtextEditor';

import Loading from '../../Loading';

export function EditProductForm({
  errors,
  Controller,
  control,
  productType,
  addOption,
  setAddOption,
  uploadedImage,
  setUploadedImage,
  productDescribe,
  setProductDescribe,
  options,
  append,
  remove,
  file,
  brand,
  video,
  warehouse,

  // taxInclude,
}) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const option = useSelector((state) => state.option);
  const product = useSelector((state) => state.product);
  console.log('product in Edit product', product);
  // console.log('id  ', id);

  useEffect(() => {
    dispatch(actions.productGet(id));
    return () => {};
  }, [option.isLoading]);

  const handleDeleteItem = async (indexId, index) => {
    if (indexId) {
      const newOptions = _.map(options, (eachOption) => {
        // eslint-disable-next-line no-underscore-dangle
        const optionData = { option: eachOption?._id };
        return optionData;
      });
      const payload = {
        ...product,
        options: newOptions,
      };
      console.log('payload ', payload);

      const confirm = window.confirm('ยืนยันการลบสินค้า');
      if (confirm) {
        try {
          console.log('ลบ ', indexId);
          await dispatch(actions.deleteOneOption(indexId));
          // eslint-disable-next-line no-underscore-dangle
          // await dispatch(actions.productPut(product?._id, payload));
        } catch (error) {
          console.error(error);
        }
        // console.log('indexId', indexId);
      }
    } else {
      remove(index);
    }
  };

  const handleAddItem = () => {
    append({
      name: '',
      price: 0,
      cost: 0,
      inventory: 0,
    });
  };

  const renderAddButton = () => (
    <Button
      variant="outlined"
      onClick={() => {
        handleAddItem();
      }}
    >
      เพิ่มออฟชั่น
    </Button>
  );
  // console.log(option);
  if (option.isLoading) {
    return <Loading />;
  }
  return (
    <div className="flex flex-row flex-wrap">
      <div className="p-2 bg-gray-600 text-white my-2 w-full">
        ข้อมูลเบื้องต้นของสินค้า
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'type_code'}
          control={control}
          defaultValue={product ? product.type_code : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสสินค้า"
              fullWidth
              size={'small'}
              required
              helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={product ? product.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อสินค้า"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'unit'}
          control={control}
          defaultValue={product ? product.unit : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน่วยสินค้า"
              fullWidth
              size={'small'}
              required
              helperText={errors.unit && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'product_type'}
          control={control}
          // eslint-disable-next-line no-underscore-dangle
          defaultValue={product ? product?.product_type?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทสินค้า
              </InputLabel>
              <Select
                {...field}
                label="ประเภทสินค้า"
                size={'small'}
                fullWidth
                required
              >
                {_.size(productType?.rows) ? (
                  _.map(productType.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'brand'}
          control={control}
          // eslint-disable-next-line no-underscore-dangle
          defaultValue={product ? product?.brand?._id : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="brand" size={'small'}>
                แบรนด์
              </InputLabel>
              <Select {...field} label="แบรนด์" size={'small'} fullWidth>
                <MenuItem>
                  <MenuItem value={null}>
                    <em>ไม่เลือก</em>
                  </MenuItem>
                </MenuItem>
                {_.size(brand?.rows) ? (
                  _.map(brand.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>

      {/* <Controller
          name={'brand'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <Autocomplete
              size={'small'}
              {...field}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              options={brand?.rows}
              getOptionLabel={(type) => `${type?.name}`}
              renderOption={(props, type) => (
                <div className="flex gap-2" {...props}>
                  <img src={type?.image?.url || ''} width="40" height="20" />
                  <div className="my-auto pl-2">{`${type?.name}`}</div>
                </div>
              )}
              renderInput={(params) => <TextField {...params} label="แบรนด์" />}
            />
          )}
        /> */}

      {!product?.option_type && (
        <>
          <div className="w-full px-1 py-2 flex ">
            <div className="w-1/2 pr-1">
              <Controller
                name={'cost_price'}
                control={control}
                defaultValue={product ? product.cost_price : ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ต้นทุน(บาท)"
                    fullWidth
                    required
                    type="number"
                    size={'small'}
                    // eslint-disable-next-line react/prop-types
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-1/2 pl-1">
              <Controller
                name={'price'}
                control={control}
                defaultValue={product ? product.price : ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ราคาขาย(บาท)"
                    fullWidth
                    required
                    type="number"
                    size={'small'}
                    // eslint-disable-next-line react/prop-types
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>

            <div className="w-1/2 pl-1">
              <Controller
                name={'warehouse'}
                control={control}
                // eslint-disable-next-line no-underscore-dangle
                defaultValue={product ? product?.warehouse : ''}
                rules={{ required: false }}
                render={({ field }) => (
                  <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                    <InputLabel id="brand" size={'small'}>
                      คลังสินค้าที่จะไป
                    </InputLabel>
                    <Select {...field} label="แบรนด์" size={'small'} fullWidth>
                      <MenuItem>
                        <MenuItem value={null}>
                          <em>ไม่เลือก</em>
                        </MenuItem>
                      </MenuItem>
                      {_.size(warehouse?.rows) ? (
                        _.map(warehouse.rows, (row) => (
                          <MenuItem key={row.id} value={row.id}>
                            {row.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>
                          <MenuItem disabled value="">
                            <em>ไม่มีข้อมูล</em>
                          </MenuItem>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            {/* <div className="w-1/2 pl-1">
              <Controller
                name={'warehouse'}
                control={control}
                defaultValue={product ? product.price : ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label=""
                    fullWidth
                    required
                    type="number"
                    size={'small'}
                    // eslint-disable-next-line react/prop-types
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div> */}
          </div>
        </>
      )}

      <div className="w-full pl-2 pt-2">
        <div className="w-full pl-2">
          <Controller
            name={'private_price'}
            control={control}
            defaultValue={product ? product.private_price : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="ไม่เเสดงราคาหน้าเว็ป ( ออกใบเสนอราคา )"
              />
            )}
          />
        </div>
      </div>
      <div className="w-full pl-2">
        <div className="w-full pl-2">
          <Controller
            name={'show_front'}
            control={control}
            defaultValue={product ? product?.show_front : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="แสดงสินค้าหน้าเว็ปไซต์"
              />
            )}
          />
        </div>
      </div>
      <div className="w-full pl-2 pb-2">
        <div className="w-full pl-2">
          <Controller
            name={'popular_product'}
            control={control}
            defaultValue={product ? product.popular_product : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="สินค้ายอดนิยม"
              />
            )}
          />
        </div>
        <div className="w-full pl-2">
          <Controller
            name={'stock_infinite'}
            control={control}
            defaultValue={product ? product.stock_infinite : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="สต๊อคไม่มีวันหมด ( ตัวอย่างเช่น กาแฟ, สินค้าที่พรีออเด้อได้ )"
              />
            )}
          />
        </div>

        {/* <div className="w-full pl-2">
          <Controller
            name={'tax_include'}
            control={control}
            defaultValue={product ? product.tax_include : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label={
                  <a className="text-red-500">สินค้ารวมภาษีมูลค่าเพิ่มแล้ว</a>
                }
              />
            )}
          />
        </div> */}
      </div>

      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'pre_order'}
          control={control}
          defaultValue={product ? product.pre_order : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนวันในการรอสินค้า กรณีสินค้าเป็นพรีออเด้อ"
              fullWidth
              type="number"
              size={'small'}
              // eslint-disable-next-line react/prop-types
            />
          )}
        />
      </div>

      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'minimum_inventory_size'}
          control={control}
          defaultValue={product ? product.minimum_inventory_size : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนสินค้าขั้นต่ำในการแจ้งเตือน"
              fullWidth
              type="number"
              size={'small'}
              required
              // eslint-disable-next-line react/prop-types
              helperText={errors.price && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      {product?.option_multiple && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'option_threshold'}
            control={control}
            defaultValue={product ? product.option_threshold : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="จำนวนออฟชั่นที่เลือกได้"
                fullWidth
                type="number"
                size={'small'}
                // eslint-disable-next-line react/prop-types
              />
            )}
          />
        </div>
      )}

      {product?.option_type && (
        <>
          <div className="p-2 bg-gray-600 text-white mx-1 w-full">
            ออฟชั่นเสริม หรือ SKU
          </div>
          <div className="mx-1 w-full ">
            <TableContainer>
              <Table sx={{ minWidth: 800 }}>
                <TableHead className="bg-yellow-100">
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> ชื่อออฟชั่น</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> ต้นทุน(บาท)</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> ราคาขาย(บาท)</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> น้ำหนัก(กรัม)</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> จำนวน/ลัง</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> ดำเนินการ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(options) ? (
                    options.map((_option, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <div className="py-1">
                            <Controller
                              name={`optionList.${index}.name`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  required
                                  label="ชื่อ"
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="py-1">
                            <Controller
                              name={`optionList.${index}.cost`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  type="number"
                                  label="ต้นทุน"
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="py-1">
                            <Controller
                              name={`optionList.${index}.price`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  required={!product?.option_threshold}
                                  type="number"
                                  label="ราคาขาย"
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="py-1">
                            <Controller
                              name={`optionList.${index}.weight`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  type="number"
                                  label="น้ำหนัก"
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="py-1">
                            <Controller
                              name={`optionList.${index}.pack_size`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  type="number"
                                  label="จำนวน/ลัง"
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <Button
                            size="sm"
                            variant="outlined"
                            color="error"
                            disabled={_option.inventory !== 0}
                            onClick={() => {
                              // eslint-disable-next-line no-underscore-dangle
                              handleDeleteItem(_option._id, index);
                            }}
                          >
                            <Delete
                              size={16}
                              color={`${
                                _option?.inventory !== 0 ? 'gray' : 'red'
                              }`}
                            />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow key={''}></TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="w-full flex justify-center py-2">
            {renderAddButton()}
          </div>
        </>
      )}

      <div className="p-2 bg-gray-600 text-white mx-1 w-full">
        ข้อมูลการจัดส่ง
      </div>
      <div className="w-full pl-2 py-2 lg:flex">
        <div className="w-full lg:w-1/2 pl-2">
          <Controller
            name={'delivery'}
            control={control}
            defaultValue={product ? product?.delivery : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="สามารถจัดส่งได้"
              />
            )}
          />
        </div>

        {/* {product?.option_type === product?.option_multiple && (
          <div className="w-full lg:w-1/2 my-auto pt-2">
            <Controller
              name={'weight'}
              control={control}
              defaultValue={product ? product.weight : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="น้ำหนักสินค้า ( กรัม )"
                  required
                  fullWidth
                  type="number"
                  size={'small'}
                  // eslint-disable-next-line react/prop-types
                />
              )}
            />
            <small className="text-red-400">( ใช้สำหรับคำนวณค่าจัดส่ง )</small>
          </div>
        )} */}
        {product?.option_type && product?.option_multiple === false ? (
          <div className="w-full lg:w-1/2 my-auto pt-2">
            <Controller
              name={'weight'}
              control={control}
              defaultValue={product ? product.weight : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="น้ำหนักสินค้า ( กรัม )"
                  required
                  fullWidth
                  type="number"
                  size={'small'}
                  // eslint-disable-next-line react/prop-types
                />
              )}
            />
            <small className="text-red-400">( ใช้สำหรับคำนวณค่าจัดส่ง )</small>
          </div>
        ) : (
          <div className="w-full lg:w-1/2 my-auto pt-2">
            <Controller
              name={'weight'}
              control={control}
              defaultValue={product ? product.weight : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="น้ำหนักสินค้า ( กรัม )"
                  required
                  fullWidth
                  type="number"
                  size={'small'}
                  // eslint-disable-next-line react/prop-types
                />
              )}
            />
            <small className="text-red-400">( ใช้สำหรับคำนวณค่าจัดส่ง )</small>
          </div>
        )}
      </div>
      <div className="w-full px-1 pb-20">
        <div className="p-2 bg-gray-600 text-white my-2">
          รายละเอียด/คำอธิบายสินค้า
        </div>
        <RichtextEditor value={productDescribe} setValue={setProductDescribe} />
      </div>

      {/* <div className="w-full lg:w-1/2 px-1 py-1">
        <Controller
          name={'serial_type'}
          control={control}
          defaultValue={serialType}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="มี Serial Number"
            />
          )}
        />
      </div> */}
      {addOption != null ? (
        <div className="w-full px-1 py-1">
          <FormControlLabel
            value="end"
            defaultValue={addOption}
            control={<Checkbox color="primary" />}
            label="เพิ่มออฟชั่นเสริมของสินค้า"
            labelPlacement="end"
            name="addUser"
            onChange={() => {
              setAddOption(!addOption);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {/* {addOption === true || option ? (
        <>
          <EditOptionForm
            selectOption={selectOption}
            setSelectOption={setSelectOption}
            option={option}
          />
        </>
      ) : (
        <></>
      )} */}
      {product.images ? (
        <>
          <div className="px-1 w-full pt-4">
            <div className="p-2 bg-gray-600 text-white my-2 w-full">
              รูปภาพของสินค้า{' '}
              <small>( รูปแรกใช้สำหรับเเสดงหน้าร้านออนไลน์ )</small>
            </div>
            <ImageUpload
              images={uploadedImage}
              setImages={setUploadedImage}
              preview_size="250"
              maxNumber={10}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {!_.isEmpty(file) ? (
        <>
          <div className="px-1 w-full pt-4">
            <div className="p-2 bg-gray-600 text-white my-2 w-full">
              เอกสารแนบ
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="px-1 w-full pt-4">
            <div className="p-2 bg-gray-600 text-white my-2 w-full">
              เอกสารแนบ
            </div>
          </div>
        </>
      )}
    </div>
  );
}

EditProductForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    unit: PropTypes.object,
    packing_size: PropTypes.object,
    packing_unit: PropTypes.object,
    inventory: PropTypes.object,
    price: PropTypes.object,
    unit_net: PropTypes.object,
    detail: PropTypes.object,
    type_code: PropTypes.object,
    option: PropTypes.object,
    options: PropTypes.array,
  }),
  product: PropTypes.object,
  productType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  addOption: PropTypes.bool,
  setAddOption: PropTypes.func,
  selectOption: PropTypes.array,
  setSelectOption: PropTypes.func,
  option: PropTypes.array,
  uploadedImage: PropTypes.array,
  setUploadedImage: PropTypes.func,
  productDescribe: PropTypes.string,
  setProductDescribe: PropTypes.func,
  privatePrice: PropTypes.bool,
  setPrivatePrice: PropTypes.func,
  delivery: PropTypes.bool,
  options: PropTypes.array,
  file: PropTypes.array,
  video: PropTypes.array,
  append: PropTypes.func,
  remove: PropTypes.func,
  showFront: PropTypes.bool,
  taxInclude: PropTypes.bool,
  popularProduct: PropTypes.bool,
  brand: PropTypes.array,
};

EditProductForm.defaultProps = {
  product: null,
  productType: null,
  brand: null,
  addOption: null,
  setAddOption: null,
  option: null,
  options: null,
};

export default EditProductForm;
