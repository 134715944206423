import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import {
  // Backdrop,
  // Fade,
  // Modal,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // IconButton,
  // Tooltip,
  Paper,
  Button,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import NavigationIcon from '@mui/icons-material/Navigation';
// ref : https://react-google-maps-api-docs.netlify.app/
// ref : https://www.npmjs.com/package/@react-google-maps/api

// link for share : "https://www.google.com/maps/search/?api=1&query=" + e.latLng.lat() + "," + e.latLng.lng()

import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const containerStyle = {
  width: '100%',
  height: '400px',
};

const placesLibrary = ['places'];

export default function DetailCustomer({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();
  const customer = useSelector((state) => state.customer);

  const [positionCurrent, setPositionCurrent] = useState({
    lat: parseFloat(customer?.position?.lat) || 13.7563309,
    lng: parseFloat(customer?.position?.lat) || 100.5017651,
  });

  console.log('positionCurrent', positionCurrent);

  useEffect(() => {
    dispatch(actions.customerGet(id));
    dispatch(actions.customerTypeAll(''));
    return () => {};
  }, []);

  const onLoad = (marker) => {
    console.log('marker: ', marker);
  };

  useEffect(() => {
    if (customer?.position?.lat) {
      setPositionCurrent({
        lat: parseFloat(customer?.position?.lat),
        lng: parseFloat(customer?.position?.lng),
      });
    }
    return () => {};
  }, [customer]);

  const handlePushToDetailOrder = (orderId) => {
    history.push(`/spm/online/${orderId}`);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (customer.isLoading || customer.rows) {
    return <Loading />;
  }

  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">ข้อมูลลูกค้า</div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ประเภท'}</div>
              <div className="w-1/2 py-4 ">{`${customer.type?.name}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'รหัสลูกค้า'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                customer.code_name || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ชื่อ'}</div>
              <div className="w-1/2 py-4 ">{`${customer.name}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ประเภทองค์กร'}
              </div>
              <div className="w-1/2 py-4 ">{`${customer.organization_type}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ชื่อบริษัท'}
              </div>
              <div className="w-1/2 py-4 ">
                <div>{`${customer.factory}`}</div>
                <div>
                  {customer?.smallfactorystatus === true ? (
                    <div>สำนักงานเล็ก สาขา {customer?.smallfactorynumber}</div>
                  ) : (
                    <div> สำนักงานใหญ่ </div>
                  )}
                </div>
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ที่อยู่'}</div>
              <div className="w-1/2 py-4  ">
                <div>{`${customer?.address}`}</div>
                {customer?.delivery?.address ? (
                  <div>
                    <div className="flex">
                      <div className="pr-2">
                        {'เลขที่' || '-'}{' '}
                        {`${customer?.delivery?.address?.number || ''}`}
                      </div>
                      <div className="pr-2">
                        {'หมู่ที่' || ''}{' '}
                        {`${
                          +customer?.delivery?.address?.village_number || ''
                        }`}
                      </div>
                      <div className="pr-2">{`${
                        customer?.delivery?.address?.detail || ''
                      }`}</div>
                      <div className="pr-2">
                        {'ตำบล' || '-'}{' '}
                        {`${customer?.delivery?.address?.sub_district || ''}`}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="pr-2">
                        {'อำเภอ' || '-'}{' '}
                        {`${customer?.delivery?.address?.district || ''}`}
                      </div>
                      <div className="pr-2">
                        {'จังหวัด' || '-'}{' '}
                        {`${customer?.delivery?.address?.province || ''}`}
                      </div>
                      <div className="pr-2">{`${
                        customer?.delivery?.address?.postcode || ''
                      }`}</div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>

              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'เลขที่ผู้เสียภาษี'}
              </div>
              <div className="w-1/2 py-4 ">{`${customer.taxes || '-'}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'เบอร์โทรศัพท์'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                customer.phone_number || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'อีเมล'}</div>
              <div className="w-1/2 py-4 ">{`${customer.email || '-'}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'แฟกซ์'}</div>
              <div className="w-1/2 py-4 ">{`${customer.fax || '-'}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'ประเภทการชำระเงิน'}
              </div>
              <div className="w-1/2 py-4 ">{`${customer.payment || '-'}`}</div>
              {customer.supplier ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold ">
                    {'ข้อมูลบัญชีธุรกรรมการเงิน'}
                  </div>
                  <div className="w-1/2 py-4 ">
                    <div className="w-1/2">
                      {'ชื่อธนาคาร'} {' : '}
                      {`${customer.bank_name}`}
                    </div>
                    <div className="w-1/2">
                      {'ชื่อบัญชี'} {' : '}
                      {`${customer.bank_account_name}`}
                    </div>
                    <div className="w-1/2">
                      {'หมายเลขบัญชี'} {' : '}
                      {`${customer.bank_account_number}`}
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'เริ่มเป็นลูกค้าเมื่อ'}
              </div>
              <div className="w-1/2 py-4">
                {dayjs(customer?.createdAt).format('D MMM BBBB')}
              </div>
            </div>
            <div id="map">
              <div id="google-map" className="p-1 mb-2">
                <LoadScript
                  googleMapsApiKey="AIzaSyAp-85dLfh4ua90wz5TmIOmqoaA64h4b1U"
                  libraries={placesLibrary}
                >
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={positionCurrent}
                    zoom={10}
                  >
                    {positionCurrent && (
                      <Marker onLoad={onLoad} position={positionCurrent} />
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${customer?.position?.lat},${customer?.position?.lng}`}
                target="_blank"
                rel="noreferrer"
                className="w-full text-center bg-yellow-400 p-1 text-white text-xl rounded-md cursor-pointer mx-1 hover:shadow-md"
              >
                นำทาง <NavigationIcon />
              </a>
              <Button></Button>
            </div>
          </Card>
        </div>
        <div>
          <div className="w-full text-center text-lg font-semibold my-4">
            ประวัติการสั่งซื้อ
          </div>
          <Card>
            <div className="">
              <Paper>
                <TableContainer component={Paper}>
                  <Table size="small" className="border-lg">
                    <TableHead className="bg-yellow-100">
                      <TableRow>
                        <TableCell>
                          <div className="font-bold">ลำดับที่</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold">
                            {' '}
                            เลขออเดอร์/วันที่สั่งซื้อ
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold"> จำนวนเงิน</div>
                        </TableCell>
                        <TableCell>
                          <div className="font-bold"> รายละเอียด</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!_.isEmpty(customer?.paymenttransactions) ? (
                        customer?.paymenttransactions.map((_order, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                            className={index % 2 === 0 ? 'bg-gray-100' : ''}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <div className="">
                                {customer?.orders[index]?.prefix}
                                {_order?.payment_type === 'quotation' &&
                                  ' ( ใบเสนอราคา )'}
                              </div>
                              <div className="">
                                {`${dayjs(_order?.createdAt).format(
                                  'D MMM BBBB',
                                )}`}
                                {' , '}
                                {`${dayjs(_order?.createdAt).format('HH:mm')}`}
                              </div>
                            </TableCell>
                            <TableCell>{_order?.amount || '-'} บาท</TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color={'info'}
                                size={'small'}
                                onClick={() => {
                                  // eslint-disable-next-line no-underscore-dangle
                                  handlePushToDetailOrder(_order?._id);
                                }}
                                startIcon={<DescriptionIcon />}
                              >
                                รายละเอียด
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow key={''}>
                          <TableCell colSpan={6}>
                            <div className="text-center">
                              ไม่มีรายการสั่งซื้อ
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailCustomer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailCustomer.defaultProps = {
  title: '',
  subtitle: '',
};
