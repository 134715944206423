import _ from 'lodash';
import {
  PRODUCT_ALL,
  PRODUCT_GET,
  PRODUCT_DEL,
  PRODUCT_PUT,
  PRODUCT_POST,
  PRODUCT_LOADING,
  PRODUCT_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const productCreate = (payload) => async (dispatch) => {
  console.log('Payload in Action', payload?.file);
  try {
    dispatch({ type: PRODUCT_LOADING });
    const ArrayUploadData = [];

    if (payload?.file) {
      console.log('upload file');
      // Upload File First
      // eslint-disable-next-line no-restricted-syntax
      for await (const file of payload?.file) {
        console.log('file in action', file);
        const formData = new FormData();
        formData.append('file', file);

        const { data: uploadData } = await api.post(
          `${process.env.REACT_APP_API_URL}/uploads/file`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        );

        console.log('Upload Data', uploadData);
        ArrayUploadData.push(uploadData);
      }

      console.log('ArrayUploadData', ArrayUploadData);

      // Then Create Comment
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/product`,
        {
          ...payload,
          files: _.map(ArrayUploadData, (e) => ({ file: e?.id })),
        },
      );

      dispatch({ type: PRODUCT_POST, payload: data });
    }
    if (payload?.video) {
      console.log('upload video');
      // Upload File First
      // eslint-disable-next-line no-restricted-syntax
      for await (const file of payload?.video) {
        console.log('file in action', file);
        const formData = new FormData();
        formData.append('file', file);

        const { data: uploadData } = await api.post(
          `${process.env.REACT_APP_API_URL}/uploads/file`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          },
        );

        console.log('Upload Data', uploadData);
        ArrayUploadData.push(uploadData);
      }

      console.log('ArrayUploadData', ArrayUploadData);

      // Then Create Comment
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/product`,
        {
          ...payload,
          videos: _.map(ArrayUploadData, (e) => ({ video: e?.id })),
        },
      );

      dispatch({ type: PRODUCT_POST, payload: data });
    } else {
      console.log('dont upload file');
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/product`,
        {
          ...payload,
        },
      );

      dispatch({ type: PRODUCT_POST, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(`ไม่สามารถสร้างสินค้าได้ ${error}`);
  }
};

export const productAll = ({
  name = '',
  size = '',
  page = 1,
  selectStocks = '',
  startDate = '',
  endDate = '',
  selectProductType = '',
  selectBrand = '',
  DashBoardPage = false,
  ignoreInsideSearch = '',
  warehouse = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product?name=${name}&size=${size}&page=${page}&selectStocks=${selectStocks}&startDate=${startDate}&endDate=${endDate}&selectProduct=${selectProductType}&selectBrand=${selectBrand}&DashBoardPage=${DashBoardPage}&ignoreInsideSearch=${ignoreInsideSearch}&warehouse=${warehouse}`,
    );
    // console.log('Data..', data);
    if (status === 200) {
      dispatch({ type: PRODUCT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};

export const productGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};

export const productPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LOADING });
    const ArrayUploadData = [];

    if (payload?.file) {
      console.log('Payload in Action', payload?.file);
      // Upload File First
      // eslint-disable-next-line no-restricted-syntax
      for await (const file of payload?.file) {
        console.log('file in action', file);
        // eslint-disable-next-line no-underscore-dangle
        if (file?._id) {
          ArrayUploadData.push(file);
        } else {
          const formData = new FormData();
          formData.append('file', file);

          const { data: uploadData } = await api.post(
            `${process.env.REACT_APP_API_URL}/uploads/file`,
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          );

          console.log('Upload Data', uploadData);
          ArrayUploadData.push(uploadData);
        }
      }

      console.log('ArrayUploadData', ArrayUploadData);

      const { data } = await api.put(
        `${process.env.REACT_APP_API_URL}/product/${id}`,
        {
          ...payload,
          // eslint-disable-next-line no-underscore-dangle
          files: _.map(ArrayUploadData, (e) => ({ file: e.id || e._id })),
        },
      );
      dispatch({ type: PRODUCT_PUT, payload: data });
    }
    if (payload?.video) {
      console.log('Payload in Action', payload?.video);
      // Upload File First
      // eslint-disable-next-line no-restricted-syntax
      for await (const file of payload?.video) {
        console.log('file in action', file);
        // eslint-disable-next-line no-underscore-dangle
        if (file?._id) {
          ArrayUploadData.push(file);
        } else {
          const formData = new FormData();
          formData.append('file', file);

          const { data: uploadData } = await api.post(
            `${process.env.REACT_APP_API_URL}/uploads/file`,
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            },
          );

          console.log('Upload Data', uploadData);
          ArrayUploadData.push(uploadData);
        }
      }

      console.log('ArrayUploadData', ArrayUploadData);

      const { data } = await api.put(
        `${process.env.REACT_APP_API_URL}/product/${id}`,
        {
          ...payload,
          // eslint-disable-next-line no-underscore-dangle
          videos: _.map(ArrayUploadData, (e) => ({ video: e?.id || e?._id })),
        },
      );
      dispatch({ type: PRODUCT_PUT, payload: data });
    } else {
      const { data } = await api.put(
        `${process.env.REACT_APP_API_URL}/product/${id}`,
        payload,
      );
      dispatch({ type: PRODUCT_PUT, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};
export const productDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/product/${id}`,
    );
    dispatch({ type: PRODUCT_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_ERROR });
    throw new Error(error);
  }
};

export const productReset = () => async (dispatch) => {
  dispatch({ type: PRODUCT_LOADING });
};
