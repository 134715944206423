import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function WarehouseForm({ errors, warehouse, Controller, control }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={warehouse ? warehouse.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อสถานที่"
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'address'}
          control={control}
          defaultValue={warehouse ? warehouse.address : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ที่อยู่"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.address && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'on_website'}
          control={control}
          defaultValue={warehouse?.on_website}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="แสดงคลังหน้าเว็ปไซต์"
            />
          )}
        />
      </div>{' '}
      <div className="text-lg  font-bold  p-2">จัดการข้อมูล</div>
      <div className="w-full px-1 py-1">
        <Controller
          name={'import'}
          control={control}
          defaultValue={warehouse?.import}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="การนำเข้า"
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-1">
        <Controller
          name={'export'}
          control={control}
          defaultValue={warehouse?.export}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="การส่งออก(ลูกค้า)"
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-1">
        <Controller
          name={'move'}
          control={control}
          defaultValue={warehouse?.move}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="การส่งออก(บีโบ้)"
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-1">
        <Controller
          name={'exceldata'}
          control={control}
          defaultValue={warehouse?.exceldata}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="อัพโหลดไฟล์รายวัน"
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-1">
        <Controller
          name={'history_import'}
          control={control}
          defaultValue={warehouse?.history_import}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="ประวัติการนำเข้า"
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-1">
        <Controller
          name={'history_move'}
          control={control}
          defaultValue={warehouse?.exceldata}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="ประวัติการย้าย"
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-1">
        <Controller
          name={'history_stocklot'}
          control={control}
          defaultValue={warehouse?.exceldata}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="ประวัติการตัดสต๊อค"
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-1">
        <Controller
          name={'history_export'}
          control={control}
          defaultValue={warehouse?.history_export}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="ประวัติการส่งออก"
            />
          )}
        />
      </div>
    </div>
  );
}

WarehouseForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    address: PropTypes.object,
  }),
  warehouse: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

WarehouseForm.defaultProps = {
  warehouse: null,
};

export default WarehouseForm;
